import dynamic from "next/dynamic"
import Block from "@/components/wrappers/block"
import classNames from "classnames"
import styles from "./report-chart.module.css"
import { useEffect, useState } from "react"

export default function ReportChart({ block }) {
  const { chart, copy, source, reverseColors } = block.attrs

  const Chart = charts[chart] ?? NotFound

  const hasGrid = [
    "alungar-fran-sargassohavet-2023",
    "utslapp-per-person-2023",
    "miljotillstandet-2023",
    "pfas-i-dricksvatten-2024"
  ]

  const hasMap = ["miljotillstandet-2023"]

  const [isMounted, setMounted] = useState(false)
  const [breakpoint, setBreakpoint] = useState(null)

  const handleResize = () => {
    const name = getBreakpoint(window.innerWidth)
    if (breakpoint !== name) {
      setBreakpoint(name)
    }
  }

  useEffect(() => {
    handleResize()
    setMounted(true)

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!isMounted) {
    return null
  }

  return (
    <Block
      block={block}
      className={classNames(styles.wrapper, chart, {
        "has-grid": hasGrid.includes(chart),
        "has-map": hasMap.includes(chart)
      })}>
      <Chart {...{ chart, copy, reverseColors, breakpoint }} />
      {source && (
        <div className="source" dangerouslySetInnerHTML={{ __html: source }} />
      )}
    </Block>
  )
}

const charts = {
  "koldioxid-atmosfar-2023": dynamic(() =>
    import("./charts/koldioxid-atmosfar-2023")
  ),
  "dagar-med-sno-2023": dynamic(() => import("./charts/dagar-med-sno-2023")),
  "textilkonsumsion-2023": dynamic(() =>
    import("./charts/textilkonsumsion-2023")
  ),
  "transport-utslapp-2023": dynamic(() =>
    import("./charts/transport-utslapp-2023")
  ),
  "vaxthusgaser-markanvandning-2023": dynamic(() =>
    import("./charts/vaxthusgaser-markanvandning-2023")
  ),
  "ekologisk-jordbruksmark-2023": dynamic(() =>
    import("./charts/ekologisk-jordbruksmark-2023")
  ),
  "miljoriskindex-bekampningsmedel-2023": dynamic(() =>
    import("./charts/miljoriskindex-bekampningsmedel-2023")
  ),
  "miljobudget-2023": dynamic(() => import("./charts/miljobudget-2023")),
  "alungar-fran-sargassohavet-2023": dynamic(() =>
    import("./charts/alungar-fran-sargassohavet-2023")
  ),
  "utslapp-per-person-2023": dynamic(() =>
    import("./charts/utslapp-per-person-2023")
  ),
  "temperaturavvikelse-2023": dynamic(() =>
    import("./charts/temperaturavvikelse-2023")
  ),
  "betesmarker-och-slatterangar-2023": dynamic(() =>
    import("./charts/betesmarker-och-slatterangar-2023")
  ),
  "miljotillstandet-2023": dynamic(() =>
    import("./charts/miljotillstandet-2023")
  ),
  "vlb-kommuner-insatser-2024": dynamic(() =>
    import("./charts/vlb-kommuner-insatser-2024")
  ),
  "pfas-i-dricksvatten-2024": dynamic(() =>
    import("./charts/pfas-i-dricksvatten-2024")
  )
}

const getBreakpoint = (width) => {
  if (width <= 640) {
    return "mobile"
  } else if (width <= 767) {
    return "tablet"
  } else {
    return "desktop"
  }
}

const NotFound = ({ chart }) => (
  <div className="p-8 text-white bg-red-110">
    Diagram &quot;{chart}&quot; hittades inte.
  </div>
)
